<template>
  <div>
    <div class="flex-row-start-between">
      <!--      <a-button class="btn-create-access" @click="onCreateNewKey"
                      :disabled="this.listOptions.loading">Create New key
            </a-button>-->
      <div><!--占位--></div>
      <a-button class="btn-create-access" @click="onRefreshList"
                :disabled="this.listOptions.loading">Refresh List
      </a-button>
    </div>
    <a-table
      :data="listOptions.noData ? [] : listData"
      :bordered="listOptions.border"
      :hoverable="listOptions.hover"
      :stripe="listOptions.stripe"
      :loading="listOptions.loading"
      :show-header="listOptions.tableHeader"
      :row-selection="listOptions.checkbox ? listSelection : undefined"
      :scroll="listOptions.scroll"
    >
      <template #columns>
        <a-table-column width="60" fixed="left">
          <template #title><span class="text-head">Node</span></template>
          <template #cell="{ record }"><span class="text-grid">{{ record.nodeName }}</span></template>
        </a-table-column>
        <a-table-column width="300">
          <template #title><span class="text-head">URL</span></template>
          <template #cell="{ record }"><span class="text-grid">{{ record.url }}</span></template>
        </a-table-column>
        <a-table-column width="240">
          <template #title><span class="text-head">Access Key</span></template>
          <template #cell="{ record }"><span class="text-grid">{{ record.accessKey }}</span></template>
        </a-table-column>
        <a-table-column width="100">
          <template #title><span class="text-head">Active</span></template>
          <template #cell="{ record }">
            <a-switch v-model="record.active" class="my-switch" @change="onAccessActiveChange(record)"
                      :disabled="disableToggle(record)">
              <template #checked><span class="text-switch">ON</span></template>
              <template #unchecked><span class="text-switch">OFF</span></template>
            </a-switch>
          </template>
        </a-table-column>
        <a-table-column width="100">
          <template #title><span class="text-head">Sync</span></template>
          <template #cell="{ record }">
            <!--            <span class="text-grid" v-if="disableToggle(record)">-</span>-->
            <span class="text-grid" v-if="record.sync">sync</span>
            <a-link v-else @click="onWebhookSync(record)">retry</a-link>
          </template>
        </a-table-column>
        <a-table-column fixed="right" width="140">
          <template #title><span class="text-head">Operator</span></template>
          <template #cell="{ record }">
            <a-link style="padding: 6px;margin-left: 5px;" @click="onWebhookView(record)">View</a-link>
            <a-link style="padding: 6px;margin-left: 5px;" @click="onWebhookEdit(record)">Edit</a-link>
            <!--
            <a-link style="padding: 6px;margin-left: 5px;" @click="onAccountDelete(record)">
              <icon-delete :style="{fontSize:'16px',color:'#000000'}" />
            </a-link>
            -->
          </template>
        </a-table-column>
      </template>
    </a-table>

    <!-- 编辑项目信息弹窗 begin -->
    <webhook-editor @webhook-updated="onWebhookUpdated" :envId="envId" ref="webhookEditor"></webhook-editor>
    <!-- 编辑项目信息弹窗 end -->
  </div>
</template>
<script>
import WebhookEditor from "@/views/iot-essence/webhook-edit";
import { queryWebhooksAllNodes, updateWebhooksAllNodes } from "@/api/webhook";

export default {
  components: {
    WebhookEditor
  },
  props: {
    envId: { // 'staging'|'prod'
      type: String,
      required: true
    },
    tenantId: { // 'staging'|'prod'
      type: String,
      required: true
    }
  },
  data() {
    return {
      listOptions: {
        border: true,
        borderCell: false,
        hover: true,
        stripe: false,
        checkbox: false,
        loading: true,
        tableHeader: true,
        noData: false,
        scroll: {
          x: 1000
          // y: 1000
        }
      },
      listSelection: {
        type: "checkbox",
        showCheckedAll: true
      },
      listData: [],
      accesskeys: []
      // createAccessVisible: false
    };
  },
  async created() {
    console.log("webhookList created! envId=", this.envId, "tenantId=", this.tenantId);
    this.onRefreshList();
  },
  watch: {
    // listData(newVal, oldVal) {
    //   console.log("watch listData");
    //   this.listOptions.noData = newVal && newVal.length > 0;
    // }
  },
  methods: {
    async onRefreshList() {
      this.listOptions.loading = true;
      const { webhooks, accessKeys } = await queryWebhooksAllNodes(this.tenantId, this.envId);
      this.listData = webhooks;
      this.accesskeys = accessKeys;
      this.listOptions.loading = false;
    },
    onAccessCreated(newAccess) {
      console.log("onAccessCreated:", JSON.stringify(newAccess));
      this.listData.push(newAccess);
    },
    async onAccessActiveChange(record) {
      console.log("onAccessActiveChange:", record.accessKey, record.active);
      this.listOptions.loading = true;
      const updated = await updateWebhooksAllNodes(record);
      if (!updated) {
        this.onRefreshList();
      } else {
        this.listOptions.loading = false;
      }
    },
    async onWebhookSync(record) {
      console.log("onWebhookSync:", record.accessKey, record.sync);
      this.listOptions.loading = true;
      const updated = await updateWebhooksAllNodes({ ...record, sync: true });
      if (!updated) {
        this.onRefreshList();
      } else {
        record.sync = true;
        this.listOptions.loading = false;
      }
    },
    onWebhookView(record) {
      console.log("onWebhookView:", record.nodeId);
      this.$refs.webhookEditor.openByView(record);
    },
    onWebhookEdit(record) {
      console.log("onWebhookEdit:", record.nodeId);
      this.$refs.webhookEditor.openByEdit(record, this.accesskeys);
    },
    onWebhookUpdated(record) {
      console.log("onWebhookUpdated:", JSON.stringify(record));
      const findArr = this.listData.filter(it => it.nodeId === record.nodeId);
      if (findArr.length >= 0) {
        this.copyFields(record, findArr[0]);
      }
    },
    disableToggle(record) {
      return (record.accessKey || "").trim() === "";
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-show-secret {
  font-size: 14px;
  font-family: Helvetica;
  color: #F97846;
  line-height: 22px;
}

.text-grid {
  font-size: 14px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 22px;
}

.text-head {
  font-size: 14px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 22px;
  text-shadow: 1px 0px 0px #F2F3F5;
}

.my-switch {
  background: #F97846;
}

.text-switch {
  font-size: 12px;
  font-family: Helvetica;
  color: #FFFFFF;
  line-height: 20px;
}

.btn-create-access {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;

  margin-bottom: 16px;
}

</style>
