import request from "@/utils/request";

export async function queryWebhooksAllNodes(tenantId, envId) {
  const response = await request({
    method: "get",
    url: `/webhooks/all-nodes`,
    params: { tenantId, envId }
  });
  if (response.code === 0) {
    return response.data; // {webhooks,accessKeys}
  } else {
    return { webhooks: [], accessKeys: [] };
  }
}

export async function updateWebhooksAllNodes(record) {
  const response = await request({
    method: "put",
    url: `/webhooks/all-nodes`,
    data: record
  });
  return response.code === 0 ? response.data : null;
}
