<template>
  <div class="main-box">
    <span class="title-main">webhooks</span>

    <!-- webhook列表 begin -->
    <a-tabs v-model="envId" class="my-tabs" :lazy-load="true" :animation="true">
      <a-tab-pane v-for="(envOption) in envOptions" :key="envOption.id" :value="envOption.id" :title="envOption.title">
        <webhook-list :envId="envOption.id" :tenantId="this.tenantId" class="list-box"></webhook-list>
      </a-tab-pane>
    </a-tabs>
    <!-- webhook列表 end -->

  </div>
</template>

<script>
import WebhookList from "@/views/iot-essence/webhook-list";

export default {
  components: {
    WebhookList
  },
  data() {
    return {
      tenantId: null,
      envId: "staging"
    };
  },
  computed: {
  },
  async created() {
    this.tenantId = this.$route.params.tenantId;
  },
  methods: {},
  watch: {}
};
</script>

<style lang="scss" scoped>

.my-tabs {
  border-radius: 16px;
  background: #FFFFFF;

  margin-top: 16px;
  padding: 0 21px 16px 21px;
}

.arco-tabs-tab {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4E5969;
  line-height: 22px;
}

.arco-tabs-tab-active {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #F97846;
  line-height: 22px;
  text-shadow: 0px -2px 0px #165DFF;
}

.list-box {
  //padding: 0 21px 16px 21px;
}

.text-edit {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F97846;
  line-height: 20px;

  float: right;
  background-color: white;
}

.text-input {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;
}

.create-title {
  font-size: 16px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 24px;
}

</style>
