<template>
  <div>
    <a-modal v-model:visible="visible" ok-text="OK" unmountOnClose
             :ok-loading="loading" @before-ok="beforeCreate" @before-cancel="beforeCancel"
             @ok="confirmCreate" @cancel="cancelCreate" :hide-cancel="true">
      <template #title><span class="create-title">Webhook</span></template>
      <div class="flex-column-start-center" style="padding: 0 4px;">
        <div class="flex-row-start-center" style="margin-top: 24px;">
          <span class="text-key">Node</span>
          <a-input :style="inputStyle" class="text-input" placeholder="Node"
                   v-model="form.nodeName" :disabled="true">
            <template #suffix>
              <a-button class="copy-box" @click="copyAk">
                <icon-copy />
              </a-button>
            </template>
          </a-input>
        </div>
        <div class="flex-row-start-center" style="margin-top: 16px;">
          <span class="text-key">URL</span>
          <a-input :style="inputStyle" class="text-input" placeholder="URL"
                   v-model="form.url" :disabled="!editable">
            <template #suffix>
              <a-button class="copy-box" @click="copySk">
                <icon-copy />
              </a-button>
            </template>
          </a-input>
        </div>
        <div class="flex-row-start-center" style="margin-top: 16px;">
          <span class="text-key">Access Key</span>
          <!--          <a-input :style="inputStyle" class="text-input" placeholder="Simple Name"
                             v-model="form.accessKey" :disabled="!editable">
                        </a-input>-->
          <a-select :style="{...inputStyle,paddingRight:null}" v-model="form.accessKey"
                    class="text-input" placeholder="Access Key" :disabled="!editable">
            <a-option v-for="(ak) in accessKeys" :key="ak" :value="ak">{{ ak }}</a-option>
          </a-select>
        </div>
        <div class="flex-row-start-center" style="margin-top: 16px;">
          <span class="text-key">Active</span>
          <a-select :style="{...inputStyle,paddingRight:null}" v-model="active"
                    class="text-input" placeholder="Active" :disabled="!editable">
            <a-option value="on">ON</a-option>
            <a-option value="off">OFF</a-option>
          </a-select>
          <!--
          <div :style="{...inputStyle,paddingRight:null}">
            <a-switch v-model="form.active" class="my-switch text-input" :disabled="!editable">
              <template #checked><span class="text-switch">ON</span></template>
              <template #unchecked><span class="text-switch">OFF</span></template>
            </a-switch>
          </div>
          -->
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { IconCopy } from "@arco-design/web-vue/es/icon";
import { copyToClipboard } from "@/utils/clipUtil";
import { updateWebhooksAllNodes, queryWebhooksAllNodes } from "@/api/webhook";

export default {
  components: {
    IconCopy
  },
  props: {
    envId: { // 'staging'|'prod'
      type: String,
      required: true
    }
  },
  data() {
    return {
      warningText: "This is the ONLY time that the secret keys can be viewed. You cannot recover them later. However, you can create new access keys at any time",
      visible: false,
      editable: true,
      loading: false,
      state: null, // create|view|edit

      inputStyle: { width: "352px", paddingRight: "0" },
      form: {
        accountId: "",
        tenantId: "",
        envId: "",
        nodeId: "",
        nodeName: "",
        url: "",
        accessKey: "",
        active: ""
      },
      accessKeys: []
    };
  },
  created() {
    console.log("AccessEditor created! envId=", this.envId);
  },
  methods: {
    // openByCreate() {
    //   console.log("openByCreate:");
    //   this.state = "create";
    //   this.assignFields({}, this.form);
    //   this.visible = true;
    //   this.editable = true;
    //   this.loading = false;
    // },
    openByView(record) {
      console.log("openByView:", JSON.stringify(record));
      this.state = "view";
      this.assignFields(record, this.form);
      this.accessKeys = [record.accessKey];
      this.visible = true;
      this.editable = false;
      this.loading = false;
    },
    openByEdit(record, accessKeys) {
      console.log("openByEdit:", JSON.stringify(record));
      this.state = "edit";
      this.assignFields(record, this.form);
      this.accessKeys = accessKeys;
      this.visible = true;
      this.editable = true;
      this.loading = false;
    },
    copyAk() {
      console.log("copyAk:");
      copyToClipboard(this.accessKey);
      this.$message.info("Access key has been copied to the clipboard");
    },
    copySk() {
      console.log("copySk:");
      copyToClipboard(this.secretKey);
      this.$message.info("Secret key has been copied to the clipboard");
    },
    beforeCancel() {
      return !this.loading;
    },
    async beforeCreate(done) {
      if (this.state === "view") {
        done();
        return;
      }
      console.log("beforeCreate: state=", this.state);
      this.editable = false;
      this.loading = true;
      if (this.state == "edit") {
        const data = await updateWebhooksAllNodes(this.form);
        if (data) {
          this.$emit("webhookUpdated", data.webhook);
        }
      }
      done();
      this.visible = false;
      this.loading = false;
    },
    confirmCreate() {
    },
    cancelCreate() {
      console.log("cancelCreate:");
    }
  },
  computed: {
    // a-select不支持bool值.用计算属性作为v-model
    active: {
      get() {
        return this.form.active ? "on" : "off";
      },
      set(val) {
        this.form.active = "on" === val;
      }
    }
  }
};
</script>
<style lang="scss" scoped>

.text-input {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;
}

.create-title {
  font-size: 16px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 24px;
}

.text-key {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;

  box-sizing: content-box;
  min-width: 110px;
  padding-right: 14px;
}

.copy-box {
  padding: 4px 12px 4px 12px;
}

.modal-icon-box {
  width: 32px;
  height: 32px;
  box-sizing: content-box;
  padding: 0 24px 0 0;
}

.modal-icon {
  font-size: 32px;
  color: #F97846;
}

.modal-icon-text {
  font-size: 16px;
  font-family: Helvetica;
  color: #1D2129;
  line-height: 24px;
}

.my-switch {
  background: #F97846;
}
</style>
